<template>
  <div
    class="animate-pulse text-orange-300"
    v-if="syncStore.transferInProgress"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      class="h-10 w-10"
      fill="currentColor"
    >
      <path
        d="M400 0C426.5 0 448 21.49 448 48V144C448 170.5 426.5 192 400 192H344V232H616C629.3 232 640 242.7 640 256C640 269.3 629.3 280 616 280H504V320H560C586.5 320 608 341.5 608 368V464C608 490.5 586.5 512 560 512H400C373.5 512 352 490.5 352 464V368C352 341.5 373.5 320 400 320H456V280H184V320H240C266.5 320 288 341.5 288 368V464C288 490.5 266.5 512 240 512H80C53.49 512 32 490.5 32 464V368C32 341.5 53.49 320 80 320H136V280H24C10.75 280 0 269.3 0 256C0 242.7 10.75 232 24 232H296V192H240C213.5 192 192 170.5 192 144V48C192 21.49 213.5 0 240 0H400zM400 48H240V144H400V48zM80 464H240V368H80V464zM560 368H400V464H560V368z"
      />
    </svg>
  </div>
</template>

<script setup>
import { useSyncStore } from "~/stores/SyncStore";

const syncStore = useSyncStore();
</script>

<style scoped></style>
