<template>
  <Disclosure
    as="nav"
    class="border-b border-gray-200 bg-white"
    v-slot="{ open }"
  >
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <NuxtLink to="/">
              <img
                src="~/assets/svg/logo.svg"
                alt="Doorscan"
                class="block h-8 w-auto lg:hidden"
              />
              <img
                src="~/assets/svg/logo.svg"
                alt="Doorscan"
                class="hidden h-8 w-auto lg:block"
              />
            </NuxtLink>
          </div>

          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <NuxtLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.route"
              :class="[
                route.name === item.route
                  ? 'border-neutral-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
              ]"
              :aria-current="route.name === item.route ? 'page' : undefined"
              >{{ item.name }}</NuxtLink
            >
            <div v-if="eventStats !== undefined && eventStats !== null"
              class="inline-flex gap-x-4 items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-800 "
            >
              <div class="italic">
                Tickets Sold: {{ eventStats.ticketsSold }}
              </div>
              <div class="italic">
                Tickets Scanned: {{ eventStats.ticketsScanned }}
              </div>
              <div class="italic">
                Tickets Remaining: {{ eventStats.ticketsRemaining }}
              </div>
              <div class="italic">
                Black Card Entries: {{ eventStats.premiumEntries }}
              </div>
            </div>
          </div>
        </div>
        <HeaderLoadingIndicator />
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3" v-if="user">
            <div>
              <MenuButton
                class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
              >
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" :src="user.image" alt="" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem>
                  <a
                    @click.prevent="signOut"
                    href="#"
                    :class="['block px-4 py-2 text-sm text-gray-700']"
                    >Sign Out</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <div
          v-if="eventStats !== undefined && eventStats !== null"
          class="block border-l-4 border-transparent bg-green-100 py-2 pl-3 pr-4 text-base font-medium text-gray-900"
        >
          <div class="font-bold underline">{{ eventStats.name }} Stats</div>
          <div class="italic">Tickets Sold: {{ eventStats.ticketsSold }}</div>
          <div class="italic">
            Tickets Scanned: {{ eventStats.ticketsScanned }}
          </div>
          <div class="italic">
            Tickets Remaining: {{ eventStats.ticketsRemaining }}
          </div>
          <div class="italic">
            Black Card Entries: {{ eventStats.premiumEntries }}
          </div>
        </div>
        <DisclosureButton
          @click.prevent="navigateTo(item.route)"
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            route.name === item.route
              ? 'border-neutral-500 bg-neutral-50 text-neutral-700'
              : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
            'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
          ]"
          :aria-current="route.name === item.route ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
      <div class="border-t border-gray-200 pt-4 pb-3" v-if="user !== null">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="user.image" alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ user.name }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ user.email }}
            </div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton
            @click.prevent="signOut"
            as="a"
            href="#"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
          >
            Sign Out
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useAuthStore } from "~/stores/AuthStore";
import { useEventStore } from "~/stores/EventStore";
import { useTicketStore } from "~/stores/TicketStore";

const route = useRoute();
const authStore = useAuthStore();
const eventStore = useEventStore();
const ticketStore = useTicketStore();
const user = authStore.user;

async function signOut() {
  authStore
    .logout()
    .then(() => navigateTo("login"))
    .catch(function (error) {
      console.log(error);
    });
}

const navigation = [{ name: "Change Event", href: "/events", route: "events" }];

const eventStats = computed(() => {
  if (eventStore.selectedEvent === null || ticketStore.eventId === null) {
    return null;
  }

  if (ticketStore.eventId === eventStore.selectedEvent.id) {
    let ticketsSold = eventStore.selectedEventTicketStats.ticketsSold;
    let ticketsScanned = eventStore.selectedEventTicketStats.ticketsScanned;
    let premiumEntries = eventStore.selectedEventTicketStats.premiumEntries;
    return {
      name: eventStore.selectedEvent.name,
      ticketsSold: ticketsSold,
      ticketsScanned: ticketsScanned,
      ticketsRemaining: ticketsSold - ticketsScanned,
      premiumEntries: premiumEntries,
    };
  }
});
</script>

<style scoped></style>
